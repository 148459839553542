export const IS_PRODUCTION = (() => window.location.href.toUpperCase().indexOf('LMLITE.TQL.COM') > -1)();
export const IS_DEV = (() => window.location.href.toUpperCase().indexOf('PROUD-ROCK') > -1)();

const apiBaseUrl = (() => {
  let apiUrl = 'https://localhost:7017';

  if (IS_PRODUCTION) {
    apiUrl = 'https://lmlightapi.azurewebsites.net';
  } else if (IS_DEV) {
    apiUrl = 'https://lmlightapi-dev.azurewebsites.net';
  }

  return apiUrl;
})();

export default apiBaseUrl;
