import { IS_PRODUCTION } from './constants';

export const msalConfig = (() => ({
  auth: {
    clientId: IS_PRODUCTION ? 'd8f65c8d-b557-499c-9df8-2d78dffcdf74' : '98123338-84bf-45d9-9d28-d572cc527eca',
    authority: 'https://login.microsoftonline.com/c4dde173-b56e-463d-a6e0-0983142ad7ed',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}))();

export const applicationSettings = (() => {
  let scopes = ['api://98123338-84bf-45d9-9d28-d572cc527eca.tql.com/Read'];

  if (IS_PRODUCTION) {
    scopes = ['api://d8f65c8d-b557-499c-9df8-2d78dffcdf74.tql.com/Read'];
  }

  console.log(scopes);

  return {
    scopes,
  };
})();
