import { AppBar, Toolbar, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import React from 'react';
import tqlWhiteLogo from '../common/content/TQL White.svg';
import ActionButtons from './ActionButtons';

interface LayoutProps {
  children: React.ReactChild;
}

function Layout(props: LayoutProps) {
  const { children } = props;

  return (
    <>
      <AppBar
        id="app-navbar"
        position="static"
        sx={{
          paddingLeft: 2, paddingRight: 2, background: '#0093d0', boxShadow: 'none',
        }}
      >
        <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center' }}>
          <img alt="TQL" src={tqlWhiteLogo} style={{ height: 'auto', marginRight: 20 }} />
          <Typography variant="h4" sx={{ flexGrow: 1 }}>Load Manager Lite</Typography>
          <Button
            variant="outlined"
            color="inherit"
            href="https://vimeo.com/745114723/9191a322a2"
            target="_blank"
            rel="noopener noreferrer"
          >
            LM Lite Training Video
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">
        <>
          <ActionButtons />
          {children}
        </>
      </Container>
    </>
  );
}

export default Layout;
