import styled from '@emotion/styled';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)({
  borderRadius: 3,
  textTransform: 'uppercase',
  fontFamily: 'Roboto',
  padding: 5,
  height: 45,
  fontWeight: 700,
  fontSize: 14,
  width: 145,
  backgroundColor: '#eee',
  color: '#333',
  borderColor: '#ccc !important', // I can't figure out how to change this without the !important
  '& .dx-button-content': {
    fontFamily: 'Roboto',
  },
  '&: hover': {
    cursor: 'pointer',
    backgroundColor: '#ccc',
  },
});

export default StyledButton;
