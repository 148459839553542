import React, { useEffect, useState, Suspense } from 'react';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser/dist/utils/BrowserConstants';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { applicationSettings } from './common/authConfig';
import Layout from './layout';

const Loads = React.lazy(() => import('./loads'));
const Carriers = React.lazy(() => import('./carriers'));
const Customers = React.lazy(() => import('./customers'));

function App() {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, applicationSettings);
  const { instance, accounts } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authenticationMessage, setAuthenticationMessage] = useState('Please wait while we sign you in...');
  const [wasAuthError, setWasAuthError] = useState(false);

  useEffect(() => {
    if (accounts[0]) {
      instance.acquireTokenSilent({
        scopes: applicationSettings.scopes,
        account: accounts[0],
      }).catch((err) => {
        console.log(err);
        setWasAuthError(true);
        setAuthenticationMessage("There was an error trying to sign you in. Please refresh page and try again. If the issue persists, please contact <a href='mailto:tsupport@tql.com>tsupport@tql.com</a> for additional help.");
      }).finally(() => {
        setIsAuthenticating(false);
      });
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, applicationSettings);
    }
  }, [error, login]);

  return (
    <>
      <Backdrop
        id="backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAuthenticating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {
        wasAuthError
        && <Typography variant="body1">{authenticationMessage}</Typography>
      }
      <AuthenticatedTemplate>
        <Layout>
          <Suspense
            fallback={(
              <Backdrop
                id="backdrop"
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          >
            <Routes>
              <Route path="/carriers" element={<Carriers />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/" element={<Loads />} />
            </Routes>
          </Suspense>
        </Layout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography variant="body1">{authenticationMessage}</Typography>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
